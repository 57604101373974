import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import CustomButton from "../../Tools/Action/Button/ButtonCustom";
import axios from "axios";
import { Alert, IconButton, Snackbar } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

export default function NewPassword() {
    let navigate = useNavigate();
    const [isLoadingPass, setIsLoadingPass] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const handleError = useHandleError()

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);

    const [isSamePasswordAndConfirmPassword, setIsSamePasswordAndConfirmPassword] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");
    const [resetToken, setResetToken] = useState<string>("");

    const [disabledButton, setDisabledButton] = useState<boolean>(true);

    const countChars = /^.{8,}$/;
    const majTinyChars = /^(?=.*[a-z])(?=.*[A-Z]).{2,}$/;
    const numberChars = /^(?=.*\d).{1,}$/;
    const specialChars = /^(?=.*[@$!%*?&]).{1,}$/;
    const spaceChars = /^(?!.*\s).{0,}$/;

    const [isCountChars, setIsCountChars] = useState<boolean>(false);
    const [isMajTinyChars, setIsMajTinyChars] = useState<boolean>(false);
    const [isNumberChars, setIsNumberChars] = useState<boolean>(false);
    const [isSpecialChars, setIsSpecialChars] = useState<boolean>(false);
    const [isSpaceChars, setIsSpaceChars] = useState<boolean>(false);

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const isEmailValid = (email: string) => emailRegex.test(email);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect( () => {
        // get params from url
        const urlParams = new URLSearchParams(window.location.search);
        setResetToken(urlParams.get('reset_token') || "");
        setEmail(urlParams.get('courriel') || "");
    }, [])

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!isEmailValid(email) || resetToken === "") {
            handleError(null, () => {
                setIsLoadingPass(false);
            }, "Lien invalide.");
        }
        setIsLoadingPass(true);

        const source = axios.CancelToken.source();

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LEA_API}/connect/new-password`,
                {
                    courriel: email,
                    reset_token: resetToken,
                    mot_de_passe: password,
                    confirmation_mot_de_passe: confirmPassword
                }
            );

            setData(response.data);
            setOpen(true);

            // wait 3 seconds before redirecting
            setTimeout(() => {
                navigate("../login");
            } , 5000);

        } catch (err: any) {
            if (err.response) {
                handleError(err, () => {
                    setIsLoadingPass(false);
                }, err.response.data.message);
            }
        }
        setIsLoadingPass(false);
        return () => {
            source.cancel("MissingPassword.tsx: Canceled request");
        };
    }

    const handleClickShowNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    }

    const handleClickShowConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    }

    useEffect( () => {
        if (countChars.test(password)) {
            setIsCountChars(true);
        } else {
            setIsCountChars(false)
        }

        if (majTinyChars.test(password)) {
            setIsMajTinyChars(true);
        } else {
            setIsMajTinyChars(false)
        }

        if (numberChars.test(password)) {
            setIsNumberChars(true);
        } else {
            setIsNumberChars(false)
        }

        if (specialChars.test(password)) {
            setIsSpecialChars(true);
        } else {
            setIsSpecialChars(false)
        }

        if (spaceChars.test(password)) {
            setIsSpaceChars(true);
        } else {
            setIsSpaceChars(false)
        }

        if (confirmPassword === password && confirmPassword !== "" && password !== "") {
            setIsSamePasswordAndConfirmPassword(true);
        } else {setIsSamePasswordAndConfirmPassword(false)}

        if (!(isCountChars && isMajTinyChars && isNumberChars && isSpecialChars && isSpaceChars && isSamePasswordAndConfirmPassword)) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }

    }, [password, confirmPassword])

    return <>
        <form onSubmit={handleSubmit} className={"py-10"}>
            <div
                className="flex flex-col shadow font-worksans bg-white w-[560px] h-[560px] justify-between items-center space-y-6 rounded-lg">
                <div
                    className={"flex w-full h-[92px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                    <div className={"flex flex-col ml-[20%] lg:ml-[83px] items-start justify-center"}>
                        <div className={"text-white font-normal text-xl"}>Changement du mot de passe</div>
                    </div>
                </div>
                <div className={"flex flex-col w-2/3 space-y-7"}>
                    <div className={"flex flex-col space-y-4 w-full"}>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>Nouveau mot de passe</div>
                            <div className="relative w-full">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/bytesize_lock.png")}
                                         className="w-6 h-6 text-gray-500" alt={"icon"}/>
                                </div>
                                <input type={newPasswordShown ? "text" : "password"} id="input-group-2" onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                       className="bg-white border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 p-2.5"
                                       placeholder="7Y8TLa7x8?dx" required
                                />
                                <div className="flex absolute inset-y-0 right-4 items-center pl-3">
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                        >
                                            {newPasswordShown ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-col space-y-4 w-full"}>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>Répéter le mot de passe</div>
                            <div className="relative w-full">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/bytesize_lock.png")}
                                         className="w-6 h-6 text-gray-500" alt={"icon"}/>
                                </div>
                                <input type={confirmPasswordShown ? "text" : "password"} id="input-group-2" onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                                       className="bg-white border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-10 p-2.5"
                                       placeholder="7Y8TLa7x8?dx" required
                                />
                                <div className="flex absolute inset-y-0 right-4 items-center pl-3">
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                        >
                                            {confirmPasswordShown ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"flex flex-col space-y-4 w-full"}>
                        <ul className={"list-disc list-inside text-xs text-left"}>
                            <li className={!isSpecialChars ? 'text-red-500' : 'text-green-500'}>Au moins un caractère special parmi @$!%*?&</li>
                            <li className={!isCountChars ? 'text-red-500' : 'text-green-500'}>D'une longueur entre 8 et 40 caractères</li>
                            <li className={!isMajTinyChars ? 'text-red-500' : 'text-green-500'}>Au moins 1 majuscule et 1 minuscule</li>
                            <li className={!isNumberChars ? 'text-red-500' : 'text-green-500'}>Au moins 1 chiffre</li>
                            <li className={!isSpaceChars ? 'text-red-500' : 'text-green-500'}>Pas d'espace</li>
                            <li className={!isSamePasswordAndConfirmPassword ? 'text-red-500' : 'text-green-500'}>Les 2 mots de passe doivent correspondre</li>
                        </ul>
                    </div>
                </div>
                <div className={"flex flex-col pb-10 justify-center items-center space-y-3"}>
                    <CustomButton isLoading={isLoadingPass} text={"Enregistrer mon nouveau mot de passe"} type={"submit"} disabled={disabledButton}/>
                </div>
            </div>
        </form>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                {data?.message}
            </Alert>
        </Snackbar>
    </>
}