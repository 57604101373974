import React from "react";

export interface UsersInterface {
    user: any;
    id: number,
    prenom: string,
    nom: string,
    mail: string,
    permissions: {
        profil: string,
        droits: any,
        perimetre: any,
    },
    client: {
        id: number,
        nom: string
    },
}

const UserContext = React.createContext<UsersInterface | null>(null);

export default function UserContextProvider({children, userData}: any) {

    return <UserContext.Provider value={userData}>
        {children}
    </UserContext.Provider>
}

export const useUserContext = () => React.useContext(UserContext);