import CustomRouter from "../Tools/CustomRouter/CustomRouter";
import {Navigate, Route, useNavigate} from "react-router-dom";
import React, {Suspense, useCallback, useEffect, useState} from "react";
import AppBar from "./AppBar";
import MainLoader from "../Tools/Feedback/MainLoader";
import Footer from "./Footer";
import UserGuide from "../UserGuide/UserGuide";
import Support from "../Support/Support";
import LegalNotice from "../LegalNotice/LegalNotice";
import UserContextProvider, {UsersInterface} from "./UserContext";
import Disconnect from "./Disconnect/Disconnect";
import {
    ADMINISTRATION,
    CONSOLIDATION,
    DISCONNECT,
    EVALUATION,
    HOME,
    LEGAL_NOTICE,
    PROFILE,
    SUPERVISION,
    SUPPORT,
    USER_GUIDE
} from "../../config/config.modules";
import Profile from "./Profile/Profile";
import CustomRoute from "../Tools/CustomRouter/CustomRoute";
import {createTheme, ThemeProvider} from "@mui/material";
import "@fontsource/work-sans";
import {useHandleError} from "../Tools/HandleError/HandleError";
import HandleFilterPerimeter from "./HandleFilterPerimeter";
import FilterBar from "./FilterBar";
import * as request from "../Tools/Utils/APIRequests/request";

const Home = React.lazy(() => /* webpackChunkName: "Home" */ import('./Home/Home'));
const Administration = React.lazy(() => /* webpackChunkName: "Administration" */ import('./Administration/Administration'));
const Consolidation = React.lazy(() => /* webpackChunkName: "Consolidation" */ import('./Consolidation/Consolidation'));
const Evaluation = React.lazy(() => /* webpackChunkName: "Evaluation" */ import('./Evaluation/Evaluation'));
const Supervision = React.lazy(() => /* webpackChunkName: "Supervision" */ import('./Supervision/Supervision'));

interface DashboardProps {
    isConnected: boolean,

    handleChangeConnected(newValue: boolean): any;
}

export default function Dashboard({isConnected, handleChangeConnected}: DashboardProps) {
    const [defaultRoute, setDefaultRoute] = useState(HOME);
    const [userData, setUserData] = useState<UsersInterface | null>(null);
    const navigate = useNavigate();
    const handleError = useHandleError();
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const navigateToDisconnect = useCallback(() => navigate("/dashboard/disconnect"), [navigate]);

    const getUserData = useCallback(async () => {
        const req = await request.get(`${process.env.REACT_APP_LEA_API}/user/me`);

        if (req.isSuccessful) {
            setUserData(req.response.data);
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else if (req.error.response) {
            if (req.error?.response?.status === 401)
                return false;
            handleError(req.error, () => navigateToDisconnect(),
                req.error.response.data.message ??
                    (req.error?.response?.status === 500 ?
                        request.EXPIRED_SESSION_MESSAGE :
                        "Erreur lors de la récupération des données utilisateur."),
                true
            );
        }
        return true;
    }, [handleError, navigateToDisconnect]);

    useEffect(() => {
        if (!localStorage.getItem('JWT')) {
            navigateToDisconnect();
            setIsLoading(false);
            return;
        }

        const fetchUserData = async () => {
            setIsLoading(true);
            const success = await getUserData();
            setIsLoading(false);
            setIsFirstLoad(false);
            return success;
        };

        if (isFirstLoad) {
            fetchUserData().then(async (success) => {
                if (!success) {
                    await fetchUserData();
                }
            });
        } else {
            const expiration = localStorage.getItem('EXPIRATION');
            if (!expiration || new Date() >= new Date(expiration)) {
                fetchUserData();
            }
        }
    }, [isFirstLoad, navigateToDisconnect, handleError, getUserData]);


    const handlePageHeight = () => {
        if (defaultRoute === 'disconnect') {
            return 'calc(100% - 56px - 40px)';
        }
        return defaultRoute === ADMINISTRATION || defaultRoute === EVALUATION ? 'calc(100% - 56px - 70px - 40px - 40px)' : 'calc(100% - 56px - 70px - 40px)';
    }

    return (
        <Suspense fallback={<MainLoader/>}>
            <ThemeProvider
                theme={createTheme({
                    typography: { fontFamily: "Work Sans,sans-serif" },
                    palette: {
                        primary: { main: "rgba(93, 57, 175, 1)" }
                    }
                })}
            >
                {isLoading ? <MainLoader/> : <HandleFilterPerimeter>
                    <UserContextProvider userData={userData}>
                        <div style={{ height: '100vh' }}>
                            <AppBar
                                isConnected={isConnected}
                                idModules={defaultRoute}
                                handleChangeConnected={handleChangeConnected}
                            />
                            <FilterBar isConnected={isConnected}/>
                            <main
                                style={{
                                    zIndex: 10000,
                                    height: handlePageHeight(),
                                    overflow: 'auto'
                                }}
                                className={"bg-MAIN_COLOR"}
                            >
                                <CustomRouter>
                                    <Route
                                        path={""}
                                        element={<Navigate to={HOME}/>}
                                    />
                                    <Route
                                        path={HOME}
                                        element={
                                            <CustomRoute
                                                app={<Home/>}
                                                newRoute={HOME}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={`${ADMINISTRATION}/*`}
                                        element={
                                            <CustomRoute
                                                app={<Administration/>}
                                                newRoute={ADMINISTRATION}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={`${EVALUATION}/*`}
                                        element={
                                            <CustomRoute
                                                app={<Evaluation/>}
                                                newRoute={EVALUATION}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={`${SUPERVISION}/*`}
                                        element={
                                            <CustomRoute
                                                app={<Supervision/>}
                                                newRoute={SUPERVISION}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={`${CONSOLIDATION}/*`}
                                        element={
                                            <CustomRoute
                                                app={<Consolidation/>}
                                                newRoute={CONSOLIDATION}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={USER_GUIDE}
                                        element={
                                            <CustomRoute
                                                app={<UserGuide/>}
                                                newRoute={USER_GUIDE}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={SUPPORT}
                                        element={
                                            <CustomRoute
                                                app={<Support/>}
                                                newRoute={SUPPORT}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={LEGAL_NOTICE}
                                        element={
                                            <CustomRoute
                                                app={<LegalNotice/>}
                                                newRoute={LEGAL_NOTICE}
                                                setDefaultRoute={setDefaultRoute}/>
                                        }
                                    />
                                    <Route
                                        path={PROFILE}
                                        element={
                                            <CustomRoute
                                                app={<Profile/>}
                                                newRoute={PROFILE}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                    <Route
                                        path={DISCONNECT}
                                        element={
                                            <CustomRoute
                                                isConnected={false}
                                                app={<Disconnect/>}
                                                newRoute={DISCONNECT}
                                                setDefaultRoute={setDefaultRoute}
                                            />
                                        }
                                    />
                                </CustomRouter>
                            </main>
                            <Footer handleChangeConnected={handleChangeConnected} isConnected={isConnected}/>
                        </div>
                    </UserContextProvider>
                </HandleFilterPerimeter>}
            </ThemeProvider>
        </Suspense>
    );
}
