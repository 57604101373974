import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AccessControl, {READ, SCOPE_WINDOW} from "../AccessControl/AccessControl";
import AnchorButton from "../Action/Button/AnchorButton";

interface HeaderBarProps {
    actions: any[],
    parent?: string,
    defaultRoute: string,
    setNewRoute: any,
}

export default function HeaderBar({actions, defaultRoute, parent, setNewRoute}: HeaderBarProps) {
    let locate = useLocation();

    useEffect(() => {
        let res = locate.pathname.split("/")
        let target: string | null = null
        res.forEach((elem: string, index: number) => {
            if (elem === parent)
                target = res[index + 1]
        })
        setNewRoute(target)
    }, [locate, parent, setNewRoute])

    return <header
        className="flex w-full h-10 bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE">
        <div className="flex w-full h-full bg-white md:block bg-opacity-10">
            <ul className="flex flex-row items-center justify-center h-full space-x-5 text-base font-medium text-white font-worksans">
                {actions.map((elem: any, index: number) =>
                    <AccessControl key={`${index} - ${elem.path} - ${parent}`} section={elem.rules} scope={SCOPE_WINDOW} right={READ}>
                        <AnchorButton
                            className={defaultRoute === elem.path ? "underline cursor-pointer" : "hover:underline cursor-pointer"}
                            url={`/dashboard/${parent}/${elem.path}`}
                            value={elem.name}></AnchorButton>
                    </AccessControl>
                )}
            </ul>
        </div>
    </header>
}