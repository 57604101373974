import { useNavigate } from "react-router-dom";

export interface FooterProps {
    isConnected?: boolean,

    handleChangeConnected(newValue: boolean): any
}

export default function Footer({isConnected = true, handleChangeConnected}: FooterProps)
{
    let navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    return <footer className="bg-CUSTOM_BLUE h-[40px] font-worksans flex items-center justify-center">
        <div className="flex-1"></div>
        <ul className="flex flex-row font-medium text-sm text-white space-x-5">
            <li className="hover:underline cursor-pointer" onClick={() => navigate('user-guide')}>
                Guide d'utilisation
            </li>
            <svg width="4" height="23" viewBox="0 0 2 23" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0V22.5" stroke="white" strokeOpacity="0.34"/>
            </svg>
            <li className="hover:underline cursor-pointer" onClick={() => navigate('support')}>
                Support
            </li>
            <svg width="4" height="23" viewBox="0 0 2 23" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0V22.5" stroke="white" strokeOpacity="0.34"/>
            </svg>
            <li className="hover:underline cursor-pointer" onClick={() => navigate('legal-notice')}>
                Mentions légales
            </li>
            <svg width="4" height="23" viewBox="0 0 2 23" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0V22.5" stroke="white" strokeOpacity="0.34"/>
            </svg>
            <li className="hover:underline cursor-pointer" onClick={() => {
                handleChangeConnected(false);
                return navigate(isConnected ? "disconnect" : "login");
            }}>
                {isConnected ? "Déconnexion" : "Connexion"}
            </li>
        </ul>
        <div className="flex-1 ml-5">
            <div className={"font-medium text-sm text-white"}>
                SECOIA © {currentYear} - tous droits réservés
            </div>
        </div>
    </footer>
}
