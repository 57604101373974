import React, {useState} from "react";
import {Collapse, IconButton} from "@mui/material";

export default function CollapseCard({
    children,
    title,
    handleChangeCollapseOpen = () => { },
    alwaysOpen = false,
    onClick = () => { },
    haveButton = false,
    textButton = "",
}: any) {
    const [isExpanded, setIsExpanded] = useState(true);

    function handleClick() {
        handleChangeCollapseOpen(!isExpanded);

        setIsExpanded(!isExpanded);
    }

    return <div className={"shadow w-full rounded-lg font-worksans"}>
        <div
            className={`flex w-full h-[50px] bg-gradient-to-r ${isExpanded ? "rounded-t-lg" : alwaysOpen ? "rounded-t-lg" : "rounded-lg"} from-BASE_PURPLE to-BASE_BLUE`}
            onClick={() => handleClick()}>
            <div className={"flex px-3 items-center justify-between py-3 w-full"}>
                <div className={"text-white font-normal text-base"}>{title}</div>
                {!alwaysOpen && <IconButton onClick={handleClick}>
                    {!isExpanded ?
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
                        </svg>
                    }
                </IconButton>}
                {!!haveButton &&
                    <button className={"flex flex-row h-full items-center space-x-1 hover:underline text-white"} onClick={onClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                        </svg>

                        <p className="text-sm text-white font-worksans">
                            {textButton}
                        </p>
                    </button>}
            </div>
        </div>
        <Collapse in={alwaysOpen ? true : isExpanded} timeout="auto" unmountOnExit>
            {children}
        </Collapse>
    </div>
}