import React, { JSX, useEffect, useState, useCallback } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useHandleError } from "../../HandleError/HandleError";
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as request from "../../../Tools/Utils/APIRequests/request";

export type OptionSelect = {
    value: string | number,
    name: string
    inDropdown?: Boolean;
};

type CustomSelectProps = {
    options: OptionSelect[];
    handleChange: (value: string | number) => unknown;
    value: string | number;
    defaultValue?: boolean;
    label?: string;
    isRequired?: boolean;
    size?: "small" | "medium" | undefined;
    disabled?: boolean;
    height?: string;
    uppercase?: boolean;
    sx?: any | null;
    url?: string | null;
    loading?: boolean;
    index0IsPlaceholder?: boolean;
    hideEmpty?: boolean;
    noOptionText?: string;
    // Function to execute instead of handleReload
    onOpen?: (setIsLoading: Dispatch<SetStateAction<boolean>>, setIsError: Dispatch<SetStateAction<boolean>>) => unknown;
    reloadOnOpen?: boolean;
    // If reload change and is not 0, reload the select
    reload?: number;
};

function getItemClassName(index0IsPlaceholder: boolean, elemValue: string | number): string | undefined {
    if ((index0IsPlaceholder && elemValue === 0) || elemValue === -1) return "text-[#999]";

    return undefined;
}

function CustomSelect({
    options,
    handleChange,
    value,
    defaultValue,
    label,
    isRequired = false,
    size = "small",
    disabled,
    height = "40px",
    uppercase,
    loading = false,
    url = null,
    sx = {
        height: height,
        backgroundColor: 'white',
        fontFamily: 'Work Sans',
        textTransform: uppercase ? 'uppercase' : 'none',
    },
    index0IsPlaceholder = false,
    hideEmpty = false,
    noOptionText,
    onOpen,
    reloadOnOpen = false,
    reload = 0
}: CustomSelectProps): JSX.Element {
    const [data, setData] = useState<OptionSelect[]>(options);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleError = useHandleError();
    const navigate = useNavigate();

    const navigateToDisconnect = useCallback(() => navigate("/dashboard/disconnect"), [navigate]);

    const handleReload = useCallback(async (): Promise<void> => {
        if (!url) return;

        setIsError(false);
        setIsLoading(true);

        const req = await request.get(url);

        if (req.isSuccessful) {
            let target: OptionSelect[] = defaultValue ? [options[0]] : [];

            req.response.data.data.forEach((item: any) => {
                target.push({
                    name: !!item.name ? item.name : item.nom,
                    value: !!item.value ? item.value : item.id,
                });
            });

            setData(target);
            setIsLoading(false);
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                setIsLoading(false);
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else {
            handleError(req.error, () => {
                setIsError(true);
                setIsLoading(false);
            }, req.error.response?.data.message);
        }
    }, [defaultValue, handleError, navigateToDisconnect, options, url]);

    useEffect((): void => {
        if (!url || defaultValue) setData(options);
    }, [options, url, defaultValue]);

    useEffect((): void => {
        if (reload || !reloadOnOpen) handleReload();
    }, [reload, reloadOnOpen, handleReload]);

    useEffect((): void => {
        if (data && !data.length && noOptionText) {
            setData([{
                value: 0,
                name: noOptionText,
                inDropdown: false
            }]);
        }
    }, [data, noOptionText]);

    const handleChangeInput = (event: any) => {
        const { target: {value} } = event;

        handleChange(value)
    };

    const inputStyle = {
        '&&.Mui-disabled': {
            color: 'gray',
            backgroundColor: '#eeeeee',
        },
    };

    return (
        <FormControl
            fullWidth
            size={size}
            required={isRequired}
        >
            <InputLabel
                required={false}
            >
                {label}
            </InputLabel>

            <Select
                value={!isLoading ? value : ""}
                label={label}
                required={isRequired}
                onChange={handleChangeInput}
                disabled={disabled}
                sx={disabled ? {...inputStyle} : sx}
                MenuProps={{
                    sx: {
                        "&& .Mui-selected": {backgroundColor: 'white'},
                    }
                }}
                onOpen={(): void => {
                    if (onOpen) onOpen(setIsLoading, setIsError);
                    else if (reloadOnOpen) handleReload();
                }}
            >
                {(isLoading || loading) && (
                    <div
                        className={"flex h-full w-full items-center justify-center"}
                    >
                        <MenuItem
                            disabled
                            value={'Chargement'}
                            sx={{
                                '&. MuiMenuItem-root': {backgroundColor: 'white'},
                            }}
                        >
                            <CircularProgress/>
                        </MenuItem>
                    </div>
                )}

                {isError && (
                    <MenuItem
                        value={""}
                        sx={{
                            '&. MuiMenuItem-root': {backgroundColor: 'white'},
                        }}
                    >
                        <Button
                            sx={{ border: 1, borderRadius: 0, borderColor: "gray" }}
                            className={"flex w-full justify-center"}
                            onClick={(): void => {
                                if (onOpen) onOpen(setIsLoading, setIsError);
                                else handleReload();
                            }}
                        >
                            Cliquer pour recharger
                        </Button>
                    </MenuItem>
                )}

                {!isError && !isLoading && !!data && !!data.map && data.map((elem: OptionSelect, index: number) => {
                    if (hideEmpty && elem.value === "") return null;

                    return (
                        <MenuItem
                            key={`${elem.value} - ${index}`}
                            value={elem.value}
                            disabled={elem.inDropdown === false || (index0IsPlaceholder && elem.value === 0)}
                            sx={{
                                "&. MuiMenuItem-root": { backgroundColor: "white" },
                                textTransform: uppercase ? "uppercase" : "none"
                            }}
                        >
                            <p className={getItemClassName(index0IsPlaceholder, elem.value)}>
                                {elem.name}
                            </p>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default React.memo(CustomSelect);