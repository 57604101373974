import getTextWidth from './getTextWidth';

function getNestedValue(obj: any, path: string): any {
    const keys = path.split('.');

    for (let key of keys) {
        if (!obj || !obj.hasOwnProperty(key)) {
            return undefined;
        }
        obj = obj[key];
    }
    return obj;
}

export default function getMinWidth(data: any[], field: string, headerName: string, padding: number = 70): number {
    const headerWidth = getTextWidth(headerName);
    const maxContentWidth = Math.max(...data.map(row => getTextWidth(getNestedValue(row, field))));

    return ((Math.max(headerWidth, maxContentWidth)) + padding) > 1000 ? 1000 : ((Math.max(headerWidth, maxContentWidth)) + padding);
}
