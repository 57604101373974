import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import * as request from "../../Tools/Utils/APIRequests/request";

interface DisconnectProps {
    migration?: boolean
}

export default function Disconnect({migration = false}: DisconnectProps)
{
    const handleError = useHandleError();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "ALLOW_PAGE_CHANGE" });
    });

    useEffect((): void => {
        request.get(`${process.env.REACT_APP_LEA_API}/disconnect`, undefined, undefined, false).then((req: request.Request): void => {
            if (!req.isSuccessful && req.error.response)
                handleError(req.error, undefined, req.error.response.data.message);
        }).finally((): void => {
            localStorage.removeItem("JWT");
            localStorage.removeItem("REFRESH");
            localStorage.removeItem("EXPIRATION");
        });
    }, [handleError]);

    return (
        <div className={"flex flex-col w-full h-full p-10 items-center"}>
            <div className={"flex flex-col w-full h-[200px] bg-white rounded-lg justify-between shadow "}>
                <div className={"flex w-full h-[45px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                    <div className={"flex pl-5 text-white font-worksans font-normal text-base items-center justify-center"}>
                        Vous êtes déconnecté
                    </div>
                </div>
                <div className={"flex flex-col space-y-3 px-2"}>
                    <div className={"flex pl-5 text-black font-worksans font-light text-base items-center justify-start"}>
                        Si vous souhaitez vous reconnecter, cliquez ici :
                        <strong
                            className={"ml-2 text-MAIN_BLUE hover:underline cursor-pointer font-semibold"}
                            onClick={() => navigate(migration ? "../login" : "../../oauth/login")}>
                            Me connecter
                        </strong>
                    </div>
                <div className={"flex w-full justify-center items-center mb-5"}></div>
                <div className={"flex pl-5 text-black font-worksans font-light text-base items-center justify-start"}>
                    Si vous souhaitez nous informer d'un problème technique et nous contacter, cliquez ici :
                    <strong
                        className={"ml-2 text-MAIN_BLUE hover:underline cursor-pointer font-semibold"}
                        onClick={() => navigate(migration ? "../support" : "../../oauth/support")}>
                        Nous contacter
                    </strong>
                </div>
            </div>
                <div className={"flex w-full justify-center items-center mb-5"}></div>
            </div>
        </div>
    );
}
