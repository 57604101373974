import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useEffect, useState} from "react";

interface CustomInputProps {
    label?: string;
    width?: string | undefined;
    value: any;
    onChange?: (value: any) => void;
    disabled?: boolean;
    readOnly?: boolean | undefined;
    sx?: any;
    helperText?: string;
    error?: boolean;
    handleClickShowPassword?: () => void;
    showPassword?: boolean;
    isPassword?: boolean;
    required?: boolean;
    suffix?: string;
    rows?: number;
    multiline?: boolean;
    type?: string;
}

export default function CustomInput({
    label,
    error,
    value,
    width = "w-full",
    disabled = false,
    readOnly,
    onChange,
    sx,
    helperText,
    handleClickShowPassword,
    showPassword = true,
    isPassword = false,
    required,
    suffix,
    rows,
    multiline = false,
    type
}: CustomInputProps) {
    const [isRegexError, setIsRegexError] = useState(false)

    useEffect(() => {
        if (value) {
            if (type === "phone") {
                const phoneRegex = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/);

                return setIsRegexError(!phoneRegex.test(value))
            }

            if (type === "email") {
                const validEmail = new RegExp(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);

                return setIsRegexError(!validEmail.test(value))
            }
        }

        return setIsRegexError(false)
    }, [type, value]);

    return (
        <div className={`flex ${width} text-sm items-center justify-start text-start`}>
            <TextField
                fullWidth
                size={"small"}
                type={type ? type : showPassword ? "text" : "password"}
                value={value}
                label={label}
                variant="outlined"
                disabled={disabled}
                error={isRegexError || error}
                onChange={onChange}
                helperText={helperText}
                required={required}
                rows={rows}
                multiline={multiline}
                inputProps={{autoComplete: 'off'}}
                InputProps={
                    isPassword ? {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    } : readOnly ? {
                        readOnly: true,
                    } : suffix ? {
                        endAdornment: (
                            <InputAdornment position="end">{suffix}</InputAdornment>
                        )
                    } : {}
                }
                sx={sx}
            />
        </div>
    )
}