import React, {useCallback, useState} from "react";

export interface Perimeter {
    pole: number[],
    division: number[],
    direction_operationnelle: number[],
    entite_juridique: number[],
    loading: boolean,
    reload: boolean
}

interface FilterPerimeterData {
    data: Perimeter,
    handleChange(newData: Perimeter): any,
    formatForAPI(): Object | null,
}

const FilterPerimeterContext = React.createContext<FilterPerimeterData>({
    data: {
        pole: [],
        division: [],
        direction_operationnelle: [],
        entite_juridique: [],
        loading: true,
        reload: true
    },
    handleChange: () => {},
    formatForAPI: () => null
});

export default function HandleFilterPerimeter({children}: any) {
    const [data, setData] = useState<Perimeter>({
        pole: [],
        division: [],
        direction_operationnelle: [],
        entite_juridique: [],
        loading: true,
        reload: true
    });

    const handleChange = useCallback((newData: Perimeter) => {
        setData(newData);
    }, [])

    const formatForAPI = (): any => {
        return {
            pole: data.pole,
            division: data.division,
            direction_operationnelle: data.direction_operationnelle,
            entite_juridique: data.entite_juridique,
        }
    }

    return <FilterPerimeterContext.Provider value={{
        data,
        handleChange: handleChange,
        formatForAPI: formatForAPI,
    }}>
        {children}
    </FilterPerimeterContext.Provider>
}