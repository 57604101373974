import React, {createContext, useCallback, useState} from "react";
import {Alert, Snackbar} from '@mui/material';

const HandleSuccessContext = createContext((message: string = 'Votre action a bien été effectuée') => {
});


export default function HandleSuccess({children}: any) {
    const [msg, setMsg] = useState<string>("Votre action a bien été effectuée");
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);

    const handleSuccess = useCallback((message: string | { [key: string]: string } = 'Votre action a bien été effectuée') => {
        let displayMessage: string;

        if (typeof message === 'object') {
            const messages = Object.values(message);
            displayMessage = messages.join(' ');
        } else {
            displayMessage = message;
        }

        setMsg(displayMessage);
        setOpenSuccess(true);
    }, []);




    return <HandleSuccessContext.Provider value={handleSuccess}>
        {children}
        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)}>
            <Alert onClose={() => setOpenSuccess(false)} severity="success" sx={{width: '100%'}}>
                {msg}
            </Alert>
        </Snackbar>
    </HandleSuccessContext.Provider>
}

export const useHandleSuccess = () => React.useContext(HandleSuccessContext);