import { Suspense } from "react";
import { Navigate, Route } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import "@fontsource/work-sans";

import CustomRouter from "../Tools/CustomRouter/CustomRouter";
import Login from "../OAuth/Login/Login";
import { MigrationLogin } from "./MigrationElement";
import MissingPassword from "../OAuth/MissingPassword/MissingPassword";
import FirstLogin from "../OAuth/FirstLogin/FirstLogin";
import UnknownIdentifier from "../OAuth/UnknownIdentifier/UnknownIdentifier";
import UserGuide from "../UserGuide/UserGuide";
import Support from "../Support/Support";
import LegalNotice from "../LegalNotice/LegalNotice";
import Disconnect from "../Dashboard/Disconnect/Disconnect";
import Header from "../OAuth/Header";
import MainLoader from "../Tools/Feedback/MainLoader";
import Migration from "./Migration";
import Footer from "../OAuth/Footer";

interface MigrationRouterProps {
    isConnected?: boolean,

    handleChangeConnected(newValue: boolean): any
}

const MIGRATION_THEME = {
    typography: {
        fontFamily: [
            "Work Sans",
            "sans-serif",
        ].join(','),
    },
    palette: {
        primary: {
            main: "rgba(93,57,175,1)",
        },
    },
}

function Disconnection({handleChangeConnected}: MigrationRouterProps)
{
    handleChangeConnected(false);
    return <Disconnect migration/>;
}

export default function MigrationRouter({isConnected = false, handleChangeConnected}: MigrationRouterProps)
{
    return (
        <Suspense fallback={<MainLoader/>}>
            <ThemeProvider theme={createTheme(MIGRATION_THEME)}>
                <div style={{height: "100vh"}} className="flex flex-col h-screen bg-MAIN_COLOR justify-between">
                    <Header navigateTo={isConnected ? "/dashboard/home" : "/oauth/login"}/>
                    <main
                        style={{
                            height: "calc(100% - 96px)",
                            overflow: "auto"
                        }}
                        className={"bg-MAIN_COLOR"}>
                        <CustomRouter>
                            <Route path={""} element={
                                isConnected ? <Migration/> : <Navigate to={"login"}/>
                            }/>
                            <Route path={"login"} element={<MigrationLogin element={
                                <Login navigateTo={".."} migrationLogin={true} handleChangeConnected={handleChangeConnected}/>
                            }/>}/>
                            <Route path={"missing-password"} element={<MigrationLogin element={
                                <MissingPassword/>
                            }/>}/>
                            <Route path={"first-login"} element={<MigrationLogin element={
                                <FirstLogin/>
                            }/>}/>
                            <Route path={"unknown-identifier"} element={<MigrationLogin element={
                                <UnknownIdentifier/>
                            }/>}/>
                            <Route path={"user-guide"} element={
                                <UserGuide/>
                            }/>
                            <Route path={"support"} element={
                                <Support/>
                            }/>
                            <Route path={"legal-notice"} element={
                                <LegalNotice/>
                            }/>
                            <Route path={"disconnect"} element={
                                <Disconnection handleChangeConnected={handleChangeConnected}/>
                            }/>
                        </CustomRouter>
                    </main>
                    <Footer isConnected={isConnected} handleChangeConnected={handleChangeConnected}/>
                </div>
            </ThemeProvider>
        </Suspense>
    );
}
