export default function ErrorNotFound() {

    return <section className="flex items-center h-full p-16">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
            <div className="max-w-md text-center">
                <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                    <span className="sr-only">Erreur </span>404
                </h2>
                <p className="text-2xl font-semibold md:text-3xl">Désolé, nous n'avons pas pu trouver cette page.</p>
                <p className="mt-4 mb-8 dark:text-gray-400">Mais ne vous inquiétez pas, vous pouvez trouver de nombreuses autres choses sur notre page d'accueil.</p>
            </div>
        </div>
    </section>

}