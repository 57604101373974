import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FormatPerimeter, Society } from "./Evaluation/Library/ToolsLibrary/PerimeterSelection";
import type { StateProps } from "../../types";
import { DEFAULT_PERIMETER } from "../../constants";
import { useHandleError } from "../Tools/HandleError/HandleError";
import * as request from "../Tools/Utils/APIRequests/request";
import MultiAutocompleteFilter from "../Tools/Action/Inputs/MultiAutocompleteFilter";
import { InitialState } from "../Tools/Store/store";

interface FilterBarProps {
    isConnected: boolean,
}

export type RangePole = {
    value: number;
    name: string;
    code_societe: string;
    divisions: RangeDivision[];
}
export type RangeDivision = {
    value: number;
    name: string;
    code_societe: string;
    directions_operationnelles: RangeDirection[];
}
export type RangeDirection = {
    value: number;
    name: string;
    code_societe: string;
    entites_juridiques: RangeLegalEntity[];
}
export type RangeLegalEntity = {
    value: number;
    name: string;
    code_societe: string;
}

export default function FilterBar({isConnected}: FilterBarProps) {
    const [perimeter, setPerimeter] = useState<FormatPerimeter>(JSON.parse(localStorage.getItem("PERIMETER") ?? "null")?.perimeter ?? DEFAULT_PERIMETER);
    const dispatch = useDispatch();
    const isLoading: boolean = useSelector((state: InitialState) => state.reloadPerimeter);
    const handleError = useHandleError();

    useEffect((): void => {
        if (!isLoading) return;

        (async (): Promise<void> => {
            const req: request.Request = await request.get(`${process.env.REACT_APP_LEA_API}/user/me/perimetre`);

            if (req.isSuccessful) {
                const pole = req.response.data.data.poles.map((item: RangePole) => (
                    {
                        value: item.value,
                        id: item.value,
                        code: item.code_societe,
                        label: `${item.code_societe} - ${item.name}`,
                        nom: item.name
                    }
                ));
                const division = req.response.data.data.poles.flatMap((pole: RangePole) => (
                    pole.divisions.map((item: RangeDivision) => ({
                        value: item.value,
                        id: item.value,
                        code: item.code_societe,
                        label: `${item.code_societe} - ${item.name}`,
                        nom: item.name
                    }))
                ));
                const direction_operationnelle = req.response.data.data.poles.flatMap((pole: RangePole) => (
                    pole.divisions.flatMap((division: RangeDivision) =>
                        division.directions_operationnelles.map((item: RangeDirection) => ({
                            value: item.value,
                            id: item.value,
                            code: item.code_societe,
                            label: `${item.code_societe} - ${item.name}`,
                            nom: item.name
                        }))
                    )
                ));
                const entite_juridique = req.response.data.data.poles.flatMap((pole: RangePole) => (
                    pole.divisions.flatMap((division: RangeDivision) =>
                        division.directions_operationnelles.flatMap((direction: RangeDirection) =>
                            direction.entites_juridiques.map((item: RangeLegalEntity) => ({
                                value: item.value,
                                id: item.value,
                                code: item.code_societe,
                                label: `${item.code_societe} - ${item.name}`,
                                nom: item.name
                            }))
                        )
                    )
                ));

                setPerimeter((current: FormatPerimeter): FormatPerimeter => {
                    localStorage.setItem("PERIMETER", JSON.stringify({
                        perimeter: {
                            ...current,
                            pole: pole,
                            division: division,
                            direction_operationnelle: direction_operationnelle,
                            entite_juridique: entite_juridique
                        },
                        rawPerimeter: {
                            pole: pole.map((item: RangePole) => item.value),
                            division: division.map((item: RangeDivision) => item.value),
                            direction_operationnelle: direction_operationnelle.map((item: RangeDirection) => item.value),
                            entite_juridique: entite_juridique.map((item: RangeLegalEntity) => item.value),
                            id_import: current.id_import?.value ?? null,
                            date_import: current.date_import
                        }
                    }));

                    return {
                        ...current,
                        pole: pole,
                        division: division,
                        direction_operationnelle: direction_operationnelle,
                        entite_juridique: entite_juridique
                    }
                });

                dispatch({ type: "RESET_PERIMETER"});
            } else if (req.isSessionExpired) {
                handleError(req.error, () => {
                }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
                dispatch({ type: "RESET_PERIMETER"});
            } else if (req.error.response) {
                handleError(req.error, () => {
                }, req.error.response.data.message);
                dispatch({ type: "RESET_PERIMETER"});
            }
        })();
    }, [dispatch, handleError, isLoading]);

    const handleChangePerimeter = useCallback(<T extends keyof FormatPerimeter>(key: T, value: FormatPerimeter[T]): void => {
        setPerimeter((current: FormatPerimeter): FormatPerimeter => {
            const __perimeter = { ...current, [key]: value };

            let formatPerPole    : number[] = [];
            let formatPerDivision: number[] = [];
            let formatPerDirOp   : number[] = [];
            let formatPerEntity  : number[] = [];

            __perimeter.pole?.forEach(item => formatPerPole.push(item.value));
            __perimeter.division?.forEach(item => formatPerDivision.push(item.value));
            __perimeter.direction_operationnelle?.forEach(item => formatPerDirOp.push(item.value));
            __perimeter.entite_juridique?.forEach(item => formatPerEntity.push(item.value));
            localStorage.setItem("PERIMETER", JSON.stringify({
                perimeter: {
                    ...__perimeter
                },
                rawPerimeter: {
                    pole: formatPerPole,
                    division: formatPerDivision,
                    direction_operationnelle: formatPerDirOp,
                    entite_juridique: formatPerEntity,
                    id_import: __perimeter.id_import?.value ?? null,
                    date_import: __perimeter.date_import || null
                }
            }));

            return __perimeter;
        });
    }, []);

    const handleBlurPermimeter = useCallback((): void => {
        dispatch({ type: "LOAD_PERIMETER" });
    }, [dispatch]);

    if (!isConnected || localStorage.getItem('JWT') == null) return null;

    return (
        <div className={"w-full bg-MAIN_COLOR"}>
            <div id="active_perimeter" className={"flex items-center space-x-3 px-10 h-full w-full bg-MAIN_COLOR gap-2"}>
                <div className={"text-[12px] text-left text-blue-800 font-semibold mb-2"}>
                    <Typography variant="body1" className={"text-start pt-5 text-neutral-700 font-worksans whitespace-nowrap font-normal"}>
                        Périmètre actif
                    </Typography>

                    <button onClick={() => { dispatch({ type: "RESET_PERIMETER" }) }}>
                        <span>
                            Réinitialiser
                        </span>
                    </button>
                </div>

                {isLoading
                    ? <div className={"pt-6 w-full items-center"}>
                        <CircularProgress size={25} />
                    </div>
                    : <>
                        <MultiAutocompleteFilter
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/pole`}
                            handleChange={(value: Society[]) => handleChangePerimeter("pole", value)}
                            value={perimeter.pole}
                            label={"Pôle"}
                            perimeter={perimeter}
                            bold
                            labelCodeSociete
                            handleBlurPermimeter={handleBlurPermimeter}
                        />
                        <MultiAutocompleteFilter
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/division`}
                            handleChange={(value: Society[]) => handleChangePerimeter("division", value)}
                            value={perimeter.division}
                            label={"Division"}
                            perimeter={perimeter}
                            bold
                            labelCodeSociete
                            handleBlurPermimeter={handleBlurPermimeter}
                        />
                        <MultiAutocompleteFilter
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/direction`}
                            handleChange={(value: Society[]) => handleChangePerimeter("direction_operationnelle", value)}
                            value={perimeter.direction_operationnelle}
                            label={"Direction opérationnelle"}
                            perimeter={perimeter}
                            bold
                            labelCodeSociete
                            handleBlurPermimeter={handleBlurPermimeter}
                        />
                       <MultiAutocompleteFilter
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/legal`}
                            handleChange={(value: Society[]) => handleChangePerimeter("entite_juridique", value)}
                            value={perimeter.entite_juridique}
                            label={"Entité juridique"}
                            perimeter={perimeter}
                            bold
                            labelCodeSociete
                            handleBlurPermimeter={handleBlurPermimeter}
                        />
                    </>
                }
                {
                    /*
                        <Tooltip title={"Recharger le périmètre"} arrow>
                            <IconButton
                                sx={{ mt: 2.5, background: 'linear-gradient(to right, #5d39af, #2e8ff6)', cursor: 'pointer' }}
                                className={"w-10 h-10"}
                                onClick={() => dispatch({ type: "LOAD_PERIMETER" })}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="white"
                                    className="w-5 h-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                </svg>
                            </IconButton>
                        </Tooltip>
                    */
                }
            </div>
        </div>
    );
}