import pdfMake from "pdfmake/build/pdfmake"
//importing the fonts whichever present inside vfs_fonts file
import pdfFonts from "pdfmake/build/vfs_fonts"
export default async function exportPDF(data: any, fileName: string) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let header: any[]  = [];
    let newFormat: any[][] = [];

    for (let key in data[0]) {
        header.push({text: key, fontSize: 7, bold: true});
    }
    newFormat.push(header);
    data.forEach((elem: any) => {
        let row: any[] = []
        for (let i in elem) {
            row.push({text: elem[i], fontSize: 6});
        }
        newFormat.push(row);
    });

    const docDefinition: any = {
        pageOrientation: 'landscape',
        pageMargins: [ 10, 10, 10, 20 ],
        footer: function (currentPage: { toString: () => string; }, pageCount: string) {
            return { text: "Page " + currentPage.toString() + ' sur ' + pageCount, alignment: 'right', style: 'normalText', fontSize: 8, margin: [0, 0, 10, 10] }
        },
        content: [
            {text: `Liste des ${fileName}(s)`, fontSize: 16, margin: [ 0, 0, 0, 10 ]},
            {
                table: {
                    body: newFormat,
                },
                layout: {
                    fillColor: function (rowIndex: number, node: any, columnIndex: any) {
                        return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                    }
                }
            },
        ]
    };

    pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
}