import React, { useEffect, useRef, useState, useReducer, useCallback, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Tools/Action/Button/ButtonCustom";
import axios from "axios";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import CustomInput from "../../Tools/Action/Inputs/CustomInput";
import moment from "moment";
import MainLoader from "../../Tools/Feedback/MainLoader";
import { Snackbar, Alert } from "@mui/material";
import isValidFrenchPhoneNumber from "../../Tools/Utils/isValidFrenchPhoneNumber";
import * as request from "../../Tools/Utils/APIRequests/request"

interface UserProfile {
    identifier: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    country: string;
}

interface PerimeterProfils {
    company: string;
    pole: string;
    operational_management: string;
    division: string;
    affected_profil: string;
    start_of_rights: string;
    end_of_rights: string;
}

interface AccessProfile {
    current_password: string;
    password: string;
    password_confirm: string;
}

interface Action {
    type: string;
    key: string;
    value: string | any;
}

const userProfileReducer = (state: UserProfile, action: Action) => {
    switch (action.type) {
        case "UPDATE":
            // @ts-ignore
            state[action.key] = action.value
            return {...state};
        case "UPDATE_ALL":
            state.identifier = action.value?.identifiant ? action.value?.identifiant : "";
            state.first_name = action.value?.prenom ? action.value?.prenom : "";
            state.last_name = action.value?.nom ? action.value?.nom : "";
            state.email = action.value?.courriel ? action.value?.courriel : "";
            state.phone_number = action.value?.telephone ? action.value?.telephone : "";
            state.country = action.value?.pays ? action.value?.pays : "";
            return {...state};
        default:
            return state;
    }
};

const perimeterProfileReducer = (state: PerimeterProfils, action: Action) => {
    switch (action.type) {
        case "UPDATE":
            // @ts-ignore
            state[action.key] = action.value
            return {...state};
        case "UPDATE_ALL":
            state.company = action.value?.societe?.nom ? action.value?.societe?.nom : "";
            state.pole = action.value?.societe?.nom_pole ? action.value?.societe?.nom_pole : "";
            state.operational_management = action.value?.societe?.nom_direction_operationnelle ? action.value?.societe?.nom_direction_operationnelle : "";
            state.division = action.value?.societe.nom_division ? action.value?.societe?.nom_division : "";
            state.affected_profil = action.value?.profil_utilisateur?.nom ? action.value?.profil_utilisateur?.nom : "";
            state.start_of_rights = action.value?.societe?.date_entree_societe ? moment(action.value?.societe?.date_entree_societe).format("DD/MM/YYYY") : "";
            state.end_of_rights = action.value?.societe?.date_sortie_societe ? moment(action.value?.societe?.date_sortie_societe).format("DD/MM/YYYY") : "";
            return {...state};
        default:
            return state;
    }
}

const accessProfileReducer = (state: AccessProfile, action: Action) => {
    switch (action.type) {
        case "UPDATE":
            // @ts-ignore
            state[action.key] = action.value
            return {...state};
        case "UPDATE_ALL":
            state.current_password = "";
            state.password = "";
            state.password_confirm = "";
            return {...state};
        default:
            return state;
    }
}

export default function Profile() {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingPass, setIsLoadingPass] = useState<boolean>(false)
    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false)
    const [data, setData] = useState<any>(null);

    const [isErrorPass, setIsErrorPass] = useState<boolean>(false);
    const [isErrorNewPass, setIsErrorNewPass] = useState<boolean>(false);
    const [isErrorConfirmPass, setIsErrorConfirmPass] = useState<boolean>(false);

    const [isErrorUser, setIsErrorUser] = useState<boolean>(false);
    const [isErrorFirstName, setIsErrorFirstName] = useState<boolean>(false);
    const [isErrorLastName, setIsErrorLastName] = useState<boolean>(false);
    const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
    const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState<boolean>(false);

    const [currentPasswordShown, setCurrentPasswordShown] = useState<boolean>(false);
    const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);

    const isMounted = useRef(null);
    const handleError = useHandleError();
    const navigate = useNavigate();

    const navigateToDisconnect = useCallback(() => navigate("/dashboard/disconnect"), [navigate]);

    const [userProfile, dispatch] = useReducer(
        userProfileReducer,
        {
            identifier: "",
            last_name: "",
            first_name: "",
            email: "",
            phone_number: "",
            country: ""
        }
    );

    const [perimeterProfile, dispatchPerimeter] = useReducer(
        perimeterProfileReducer,
        {
            company: "",
            pole: "",
            operational_management: "",
            division: "",
            affected_profil: "",
            start_of_rights: "",
            end_of_rights: "",
        }
    );

    const [accessProfile, dispatchAccess] = useReducer(
        accessProfileReducer,
        {
            current_password: "",
            password: "",
            password_confirm: "",
        }
    );


    useEffect(() => {
        // @ts-ignore
        isMounted.current = true
        const source = axios.CancelToken.source();
        setIsLoading(true);
        (async () => {
            const req = await request.get(`${process.env.REACT_APP_LEA_API}/user/me`, undefined, source);

            if (req.isSuccessful) {
                if (isMounted && isMounted.current) {
                    setData(req.response.data);
                    dispatch({ type: "UPDATE_ALL", value: req.response.data, key: "" });
                    dispatchPerimeter({ type: "UPDATE_ALL", value: req.response.data, key: "" });
                    setIsLoading(false);
                }
            } else if (req.isSessionExpired) {
                handleError(req.error, () => {
                    setIsLoading(false);
                    navigateToDisconnect();
                }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
            } else if (req.error.response) {
                handleError(req.error, () => {
                    setIsLoading(false);
                }, req.error.response.data.message);
            }
        })();
        return () => {
            // @ts-ignore
            isMounted.current = false
            source.cancel("Profile");
        };
    }, [handleError, navigateToDisconnect])

    const handleChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
        if (isErrorUser || isErrorFirstName || isErrorLastName || isErrorEmail) {
            setIsErrorUser(false);
            setIsErrorFirstName(false);
            setIsErrorLastName(false);
            setIsErrorEmail(false);
        }
        dispatch({type: "UPDATE", value: event.target.value, key: key});
    }

    const handleChangePerimeter = (event: ChangeEvent<HTMLInputElement>, key: string) => {
        dispatchPerimeter({type: "UPDATE", value: event.target.value, key: key});
    }

    const handleChangeAccess = (event: ChangeEvent<HTMLInputElement>, key: string) => {
        if (isErrorPass || isErrorNewPass || isErrorConfirmPass) {
            setIsErrorPass(false);
            setIsErrorNewPass(false);
            setIsErrorConfirmPass(false);
        }
        dispatchAccess({type: "UPDATE", value: event.target.value, key: key});
    }

    const [open, setOpen] = useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const handleSubmitProfile = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (userProfile.first_name === "" || userProfile.last_name === "" || userProfile.email === "" || userProfile.identifier === "") {
            handleError(
                null,
                () => {
                    !userProfile.identifier && setIsErrorUser(true);
                    !userProfile.email && setIsErrorEmail(true);
                    !userProfile.first_name && setIsErrorFirstName(true);
                    !userProfile.last_name && setIsErrorLastName(true);
                    setIsLoadingUser(false)
                },
                "L'identifiant, le nom, le prénom et l'adresse email sont obligatoires."
            )
            return;
        }
        if (!isValidFrenchPhoneNumber(userProfile.phone_number)) {
            handleError(
                null,
                () => {
                    setIsErrorPhoneNumber(true);
                    setIsLoadingUser(false)
                },
                "Le numéro de téléphone n'est pas valide."
            )
            return;
        }
        setIsLoadingUser(true);

        const source = axios.CancelToken.source();
        const req = await request.put(
            `${process.env.REACT_APP_LEA_API}/user/me/edit`,
            {
                "identifiant": userProfile.identifier,
                "nom": userProfile.last_name,
                "prenom": userProfile.first_name,
                "courriel": userProfile.email,
                "telephone": userProfile.phone_number,
                "pays": userProfile.country,
            }, undefined, source
        );

        if (req.isSuccessful) {
            if (req.response?.status === 200) {
                setIsLoadingUser(false);
                setOpen(true);
            }
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                setIsLoadingUser(false);
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        }
    }

    const checkPassword = (password: string) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+,.?{}[\]\\;':"|<>~`-])[A-Za-z\d!@#$%^&*()_+,.?{}[\]\\;':"|<>~`-]{8,}$/;
        return regex.test(password);
    };

    const handleSubmitAcces = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!accessProfile.password || !accessProfile.current_password || !accessProfile.password_confirm) {
            handleError(
                null,
                () => {
                    !accessProfile.password && setIsErrorNewPass(true)
                    !accessProfile.current_password && setIsErrorPass(true)
                    !accessProfile.password_confirm && setIsErrorConfirmPass(true)
                    setIsLoadingPass(false)
                },
                "Vérifiez que tous les champs soient bien remplis.",
            )
            return;
        }
        if (accessProfile.password !== accessProfile.password_confirm) {
            handleError(
                null,
                () => {
                    setIsErrorNewPass(true)
                    setIsErrorConfirmPass(true)
                    setIsLoadingPass(false)
                },
                "Les mots de passe ne sont pas identiques.",
            )
            return;
        }
        if (!checkPassword(accessProfile.password)) {
            handleError(
                null,
                () => {
                    setIsErrorNewPass(true)
                    setIsLoadingPass(false)
                },
                "Le nouveau mot de passe doit comporter au moins une majuscule, un chiffre et un caractère spécial.",
            )
            return;
        }
        setIsLoadingPass(true);

        const source = axios.CancelToken.source();
        const req = await request.put(
            `${process.env.REACT_APP_LEA_API}/user/me/edit-password`,
            {
                "ancien_mot_de_passe": accessProfile.current_password,
                "nouveau_mot_de_passe": accessProfile.password,
                "nouveau_mot_de_passe_confirmation": accessProfile.password_confirm,
            }, undefined, source
        );

        if (req.isSuccessful) {
            setData(req.response.data);
            dispatchAccess({ type: "UPDATE_ALL", value: "", key: "" });
            setIsLoadingPass(false);
            setOpen(true);
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                setIsErrorPass(true);
                setIsLoadingPass(false);
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else if (req.error.response) {
            handleError(req.error, () => {
                setIsErrorPass(true);
                setIsLoadingPass(false);
            }, req.error.response.data.message ?? "Le mot de passe actuel est incorrect.");
        }
        return () => {
            source.cancel("Profile");
        };
    }

    const handleClickShowCurrentPassword = () => {
        setCurrentPasswordShown(!currentPasswordShown);
    }

    const handleClickShowNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    }

    const handleClickShowConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    }

    return (
        <>
            {isLoading ? MainLoader() :
                <div className={"flex pb-10 2xl:pb-0 w-full text-worksans"}>
                    <div className={"flex flex-col w-full h-full items-start px-10 py-5 space-y-8"}>
                        <div className={"text-2xl text-gray-800 font-semibold"}>Compte utilisateur</div>
                        <div className={"flex flex-col w-full h-[300px] lg:h-[230px] bg-white rounded-lg shadow"}>
                            <div
                                className={"flex w-full h-[50px] rounded-t-lg bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE"}>
                                <div className={"flex flex-row space-x-3 pl-5 items-center justify-center text-white"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                                    </svg>
                                    <div className={"font-normal text-lg"}>Individu</div>
                                </div>
                            </div>
                            <form onSubmit={handleSubmitProfile}
                                  className={"flex flex-row space-x-5 px-5 w-full h-[250px] lg:h-[180px] justify-center items-center"}>
                                <div className={"grid grid-cols-2 lg:grid-cols-3 gap-9 w-[90%] "}>
                                    <CustomInput
                                        label="Identifiant :"
                                        value={userProfile.identifier}
                                        error={isErrorUser}
                                        onChange={(e) => handleChange(e, "identifier")}
                                    />
                                    <CustomInput
                                        label="Nom :"
                                        value={userProfile.last_name}
                                        error={isErrorLastName}
                                        onChange={(e) => handleChange(e, "last_name")}
                                    />
                                    <CustomInput
                                        label="Prénom :"
                                        value={userProfile.first_name}
                                        error={isErrorFirstName}
                                        onChange={(e) => handleChange(e, "first_name")}
                                    />
                                    <CustomInput
                                        label="Adresse mail :"
                                        value={userProfile.email}
                                        error={isErrorEmail}
                                        onChange={(e) => handleChange(e, "email")}
                                    />
                                    <CustomInput
                                        label="N° de téléphone :"
                                        value={userProfile.phone_number}
                                        error={isErrorPhoneNumber}
                                        onChange={(e) => handleChange(e, "phone_number")}
                                    />
                                    <CustomInput
                                        label="Pays :"
                                        value={userProfile.country}
                                        onChange={(e) => handleChange(e, "country")}
                                    />
                                </div>
                                <div className={"flex"}>
                                    <CustomButton type="submit" isLoading={isLoadingUser} text={"Enregistrer"}/>
                                </div>
                            </form>
                        </div>

                        <div
                            className={"flex flex-col w-full h-[360px] lg:h-[300px] 2xl:h-[250px] bg-white rounded-lg shadow"}>
                            <div
                                className={"flex w-full h-[50px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                                <div className={"flex flex-row space-x-3 pl-5 items-center justify-center text-white"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                                    </svg>
                                    <div className={"font-normal text-lg"}>Société</div>
                                </div>
                            </div>
                            <div
                                className={"flex flex-col w-full h-[310px] lg:h-[250px] 2xl:h-[200px] justify-center px-5"}>
                                <div className={"grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 2xl:gap-10"}>
                                    <CustomInput
                                        readOnly={true}
                                        label="Société :"
                                        value={perimeterProfile.company}
                                        onChange={(e) => handleChangePerimeter(e, "company")}
                                    />
                                    <CustomInput
                                        readOnly={true}
                                        label="Pôle :"
                                        value={perimeterProfile.pole}
                                        onChange={(e) => handleChangePerimeter(e, "pole")}
                                    />
                                    <CustomInput
                                        readOnly={true}
                                        label="Direction opérationnelle :"
                                        value={perimeterProfile.operational_management}
                                        onChange={(e) => handleChangePerimeter(e, "operational_management")}
                                    />
                                    <CustomInput
                                        readOnly={true}
                                        label="Division :"
                                        value={perimeterProfile.division}
                                        onChange={(e) => handleChangePerimeter(e, "division")}
                                    />
                                    <CustomInput
                                        readOnly={true}
                                        label="Profil affecté :"
                                        value={perimeterProfile.affected_profil}
                                        onChange={(e) => handleChangePerimeter(e, "affected_profil")}
                                    />
                                    <CustomInput
                                        readOnly={true}
                                        label="Début des droits :"
                                        value={perimeterProfile.start_of_rights}
                                        onChange={(e) => handleChangePerimeter(e, "start_of_rights")}
                                    />
                                    <CustomInput
                                        readOnly={true}
                                        label="Fin des droits :"
                                        value={perimeterProfile.end_of_rights}
                                        onChange={(e) => handleChangePerimeter(e, "end_of_rights")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col w-full h-[200px] bg-white rounded-lg shadow"}>
                            <div
                                className={"flex w-full h-[50px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                                <div className={"flex flex-row space-x-3 pl-5 items-center justify-center text-white"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
                                    </svg>
                                    <div className={"font-normal text-lg"}>Code d'accès</div>
                                </div>
                            </div>
                            <div className={"flex h-[150px] items-center"}>
                                <form onSubmit={handleSubmitAcces}
                                      className={"grid grid-cols-4 w-full h-[50px] px-5 items-start gap-5"}>
                                    <CustomInput
                                        label="Mot de passe actuel :"
                                        error={isErrorPass}
                                        isPassword={true}
                                        showPassword={currentPasswordShown}
                                        handleClickShowPassword={handleClickShowCurrentPassword}
                                        value={accessProfile.current_password}
                                        onChange={(e) => handleChangeAccess(e, "current_password")}
                                        helperText={"\t"}
                                    />
                                    <CustomInput
                                        label="Nouveau mot de passe :"
                                        error={isErrorNewPass}
                                        isPassword={true}
                                        showPassword={newPasswordShown}
                                        handleClickShowPassword={handleClickShowNewPassword}
                                        value={accessProfile.password}
                                        onChange={(e) => handleChangeAccess(e, "password")}
                                        helperText={"8 caractères minimum dont 1 majuscule, 1 chiffre et 1 caractère spécial"}
                                    />
                                    <CustomInput
                                        label="Confirmation du mot de passe :"
                                        error={isErrorConfirmPass}
                                        isPassword={true}
                                        showPassword={confirmPasswordShown}
                                        handleClickShowPassword={handleClickShowConfirmPassword}
                                        value={accessProfile.password_confirm}
                                        onChange={(e) => handleChangeAccess(e, "password_confirm")}
                                        helperText={"\t"}
                                    />
                                    <div className={"flex justify-center items-center"}>
                                        <CustomButton isLoading={isLoadingPass} type="submit" text={"Modifier"}/>
                                    </div>
                                </form>
                            </div>
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                                    {data?.message ? data.message : "Success"}
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}