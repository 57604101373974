import React from "react";
import {Snackbar} from "@mui/material";

interface AlertsSnackBarProps {
    handleClose(): void,

    open: boolean,
    message: string,
    success?: boolean
}

export default function AlertsSnackBar({handleClose, open, message, success = true}: AlertsSnackBarProps) {
    return <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={open} autoHideDuration={6000}
                     onClose={handleClose}>
        {success ?
            <div className="flex flex-row h-full w-full space-x-5 items-center justify-center p-4 text-sm rounded-lg"
                 role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                    {message}
                </div>
            </div>
            :
            <div className="flex flex-row h-full w-full space-x-5 items-center justify-center p-4 text-sm rounded-lg bg-red-200 text-red-600"
                 role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                    {message}
                </div>
            </div>
        }
    </Snackbar>
}