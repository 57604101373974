import { List, Popover, Tooltip, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, InputAdornment, Dialog, DialogTitle, DialogActions, Button, DialogContent, debounce, Divider } from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import type { KeyboardEvent } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AlertsSnackBar from "../Feedback/AlertsSnackBar";
import DialogHelp from "./Dialog/DialogHelp";
import DialogFilename from "./Dialog/DialogFilename";
import {GridRowId} from "@mui/x-data-grid";
import AccessControl, { ADD, ARCHIVING, DELETE, EXPORT, SCOPE_TABLE, UPDATE } from "../AccessControl/AccessControl";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ToolsBarTable({
    data,
    columns,
    select,
    origin = 'document',
    handleOpenCreate = undefined,
    handleOpenDelete = undefined,
    disableSearch = false,
    isDeleteDisable = false,
    isUpdateDisable = false,
    isRestoreDisable = false,
    handleOpenUpdate = undefined,
    handleFilterSearch = undefined,
    handleOpenRestore = undefined,
    handleOpenLock = undefined,
    isLockDisable = false,
    handleDuplicate = undefined,
    rolesSection,
    handleChangeFilter,
    exportUrl = null,
    filter = null,
    resetSearch = false,
    isReferenceDisable = false,
    handleOpenReference = undefined,
}: any) {
    const [anchorElExport, setAnchorElExport] = useState<HTMLButtonElement | null>(null);
    const [anchorElSearch, setAnchorElSearch] = useState<HTMLButtonElement | null>(null);
    const [alertCopy, setAlertCopy] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [openDialogHelp, setOpenDialogHelp] = useState({title: "", content: "", open: false})
    const [isLoadingDuplicate, setIsLoadingDuplicate] = useState<boolean>(false)
    const [openDialogDuplication, setOpenDialogDuplication] = useState<boolean>(false);
    const [nameDuplication, setNameDuplication] = useState<string>("");
    const [isReference, setIsReference] = useState(false);
    const [openDialogFileName, setOpenDialogFileName] = useState<{
        open: boolean,
        type: "PDF" | "EXCEL" | "PDF_ALL" | "EXCEL_ALL"
    }>({
        open: false,
        type: "PDF"
    });

    useEffect(() => setSearch(""), [resetSearch]);

    const handleOpenExport = (event: any) => {
        setAnchorElExport(event.currentTarget);
    };

    const handleOpenDuplicate = () => {
        setOpenDialogDuplication(true)
    }

    const handleCloseDuplication = () => {
        setNameDuplication("");
        setOpenDialogDuplication(false)
    }

    const handleOpenSearch = (event: any) => {
        setAnchorElSearch(event.currentTarget);
    };

    async function handleSubmit(event: any) {
        event.preventDefault();
        setIsLoadingDuplicate(true);
        await handleDuplicate(nameDuplication);
        handleCloseDuplication();
        setIsLoadingDuplicate(false);
    }

    const handleCloseExport = () => {
        setAnchorElExport(null);
    };

    const handleCloseSearch = () => {
        setAnchorElSearch(null);
    };

    function isNumber(value: any): boolean {
        return typeof value === 'number' || /^\d+(\.\d+)?$/.test(value);
    }

    function isDate(value: any): boolean {
        return value instanceof Date || /^\d{4}-\d{2}-\d{2}$/.test(value);
    }

    function formatDataWithoutSelect(newData: any, isExcel: boolean) {
        return newData.map((row: any) => {
            const newRow: any = {};

            for (const col of columns) {
                if (!col.isExportable) {
                    let value;
                    if (isExcel && col.exportExcelValue)
                        value = col.exportExcelValue(row);
                    else
                        value = col.exportValue ? col.exportValue(row) : row[col.field];

                    if (isNumber(value) && value[0] !== '0') {
                        newRow[col.headerName] = parseFloat(value);
                    } else if (isDate(value)) {
                        newRow[col.headerName] = value.toLocaleDateString();
                    } else {
                        newRow[col.headerName] = value;
                    }
                }
            }

            return newRow;
        }).filter((item: any) => item !== null);
    }

    function formatData(isExcel: boolean) {
        const newFormat = select.map((selectedId: GridRowId) => {
            const row = data.find((rowData: any) => rowData.id === selectedId);

            if (row) {
                const newRow = columns.reduce((result: any, col: any) => {
                    if (!col.isExportable) {
                        let value;
                        if (isExcel && col.exportExcelValue)
                            value = col.exportExcelValue(row);
                        else
                            value = col.exportValue ? col.exportValue(row) : row[col.field];

                        if (isNumber(value)) {
                            result[col.headerName] = parseFloat(value);
                        } else if (isDate(value)) {
                            result[col.headerName] = value.toLocaleDateString();
                        } else {
                            result[col.headerName] = value;
                        }
                    }
                    return result;
                }, {});

                return newRow;
            }

            return null; // Retourne null si aucune ligne correspondante n'a été trouvée
        });

        return newFormat.filter((item: any) => item !== null);
    }

    function formatDataSearch() {
        return data.map((row: any) => {
            let newRow: any = {};

            columns.forEach((col: any) => {
                if (!col.isExportable) {
                    newRow[col.headerName] = col.exportValue
                        ? col.exportValue(row)
                        : row[col.field]
                    ;
                }
            });

            return newRow;
        });
    }

    const debouncedHandleSearch = useCallback(debounce((value: string) => {
            const indexSelected: number[] = [];
            const dataSelected: any[] = [];

            if (value === "") {
                handleFilterSearch(data);

                return;
            }

            const formattedData: any[] = formatDataSearch();

            formattedData.forEach((elem: any, index: number) => {
                for (let key in elem) {
                    if (!!elem[key] && !!elem[key].includes && elem[key].toLowerCase().includes(value.toLowerCase())) {
                        indexSelected.push(index);

                        break;
                    }
                }
            });

            indexSelected.forEach((item: any) => {
                dataSelected.push(data[item]);
            });

            handleFilterSearch(dataSelected);
    }, 300), [data, handleFilterSearch, formatDataSearch]);

    const pressEnterSearch = (e: KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === 'Enter') {
            handleChangeFilter(search);
        }
    }

    function handleSearch(e: any) {
        setSearch(e.target.value);
        debouncedHandleSearch(e.target.value);
    }

    return <>
        <div className={'flex flex-col w-[50px] h-auto align-middle space-y-3 bg-[#4A5BCB] p-2 rounded-l-lg'}>
            {handleFilterSearch !== undefined &&
                <button
                    className={`p-1 mt-[5px] ${!disableSearch ? "stroke-white" : "stroke-gray-400"}${!disableSearch ? " hover:stroke-MAIN_BLUE hover:bg-white hover:rounded-full" : ""}`}
                    onClick={handleOpenSearch}
                    disabled={disableSearch}
                >
                    <Tooltip title={!disableSearch ? "Rechercher" : ""} placement="top">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            className="w-6 h-6 mt"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                        </svg>
                    </Tooltip>
                </button>
            }

            {handleDuplicate !== undefined &&
                <button
                    className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                    onClick={handleOpenDuplicate}
                >
                    <Tooltip title="Duplication" placement="right">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                            />
                        </svg>
                    </Tooltip>
                </button>
            }

            <AccessControl scope={SCOPE_TABLE} section={rolesSection} right={EXPORT}>
                <button
                    className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                    onClick={handleOpenExport}
                >
                    <Tooltip title="Exporter" placement="right">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
                            />
                        </svg>
                    </Tooltip>
                </button>
            </AccessControl>

            <button
                className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                onClick={isDeleteDisable
                    ? () => setOpenDialogHelp({
                        title: "Impossible de Copier les éléments",
                        content: "Veuillez sélectionner au moins un élément(s) du tableau",
                        open: true
                    })
                    : async () => {
                        let target: any[]  = [];
                        let string: string = "";

                        select.forEach((item: any) => target.push(data.find((elem: {
                            id: any;
                        }) => elem.id === item)));

                        target.forEach(item => {
                            columns.forEach((col: any, index: number) => {
                                if (!col.isExportable) {
                                    if (col.exportValue) {
                                        string += col.exportValue(item);
                                    } else {
                                        string += item[col.field];
                                    }
                                }

                                if (index !== columns.length - 1) {
                                    string += '\t';
                                }
                            });
                            string += '\n';
                        });

                        navigator.clipboard.writeText(string).then(() => setAlertCopy(true));
                    }
                }
            >
                <Tooltip title="Copier" placement={"right"}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                        />
                    </svg>
                </Tooltip>
            </button>

            {handleOpenCreate !== undefined &&
                <AccessControl scope={SCOPE_TABLE} section={rolesSection} right={ADD}>
                    <button
                        className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                        onClick={handleOpenCreate}
                    >
                        <Tooltip title="Création" placement="right">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 4.5v15m7.5-7.5h-15"
                                />
                            </svg>
                        </Tooltip>
                    </button>
                </AccessControl>
            }

            {handleOpenDelete !== undefined &&
                <AccessControl scope={SCOPE_TABLE} section={rolesSection} right={DELETE}>
                    <button
                        className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                        onClick={isDeleteDisable
                            ? () => setOpenDialogHelp({
                                title: "Suppression impossible",
                                content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                open: true
                            })
                            : handleOpenDelete
                        }
                    >
                        <Tooltip title="Supprimer" placement="right">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>
                        </Tooltip>
                    </button>
                </AccessControl>
            }

            {handleOpenRestore !== undefined &&
                <button
                    className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                    onClick={isRestoreDisable
                        ? () => setOpenDialogHelp({
                            title: "Restauration impossible",
                            content: "Veuillez sélectionner au moins un élément(s) du tableau",
                            open: true
                        })
                        : handleOpenRestore
                    }
                >
                    <Tooltip title="Restaurer" placement="right">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                            />
                        </svg>
                    </Tooltip>
                </button>
            }

            {handleOpenReference!== undefined &&
                <AccessControl scope={SCOPE_TABLE} section={rolesSection} right={ADD}>
                    <button
                        className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                        onClick={isReferenceDisable
                            ? () => setOpenDialogHelp({
                                title: "Verrouillage impossible",
                                content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                open: true
                            })
                            : handleOpenReference
                        }
                    >
                        <Tooltip title="Référencer" placement="right">
                            <svg
                                strokeWidth={0.2}
                                fill="currentColor"
                                viewBox="0 -950 950 950"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 stroke-white hover:fill-MAIN_BLUE fill-white"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M512.462-303.924 596-367.77l83.538 63.846-30.923-103.615 83.922-67.845H628.923L596-576.999l-32.923 101.615H459.463l83.922 67.845-30.923 103.615ZM172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h219.613l80 80h315.769q30.308 0 51.308 21t21 51.308v375.382q0 30.308-21 51.308t-51.308 21H172.309Zm0-59.999h615.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-375.382q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H447.385l-80-80H172.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v455.382q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462ZM160-240v-480 480Z"
                                />
                            </svg>
                        </Tooltip>
                    </button>
                </AccessControl>
            }

            {handleOpenLock !== undefined &&
                <AccessControl scope={SCOPE_TABLE} section={rolesSection} right={ARCHIVING}>
                    <button
                        className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                        onClick={isLockDisable
                            ? () => setOpenDialogHelp({
                                title: "Verrouillage impossible",
                                content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                open: true
                            })
                            : handleOpenLock
                        }
                    >
                        <Tooltip title="Verrouiller" placement="right">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                />
                            </svg>
                        </Tooltip>
                    </button>
                </AccessControl>
            }

            {handleOpenUpdate !== undefined &&
                <AccessControl scope={SCOPE_TABLE} section={rolesSection} right={UPDATE}>
                    <button
                        className="hover:bg-white hover:rounded-full p-1 stroke-white hover:stroke-MAIN_BLUE"
                        onClick={isUpdateDisable
                            ? () => setOpenDialogHelp({
                                title: "Modification impossible",
                                content: "Veuillez sélectionner un élément du tableau",
                                open: true
                            })
                            : handleOpenUpdate
                        }
                    >
                        <Tooltip title="Modifier" placement="bottom">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                            </svg>
                        </Tooltip>
                    </button>
                </AccessControl>
            }
        </div>

        <Popover
            open={Boolean(anchorElSearch)}
            anchorEl={anchorElSearch}
            onClose={handleCloseSearch}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div className={"fixed w-[500px] bg-white rounded-lg z-10"}>
                <TextField
                    focused
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={pressEnterSearch}
                    size={"small"}
                    InputProps={{
                        autoComplete: 'off',
                        startAdornment: (
                            <InputAdornment position="start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                </svg>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <div className={"flex flex-row space-x-2"}>
                                <button
                                    onClick={() => {
                                        setSearch("")
                                        handleFilterSearch(data)
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>

                                <button onClick={handleCloseSearch}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )
                    }}
                    variant={"outlined"}
                    sx={{width: "500px"}}
                    placeholder="Rechercher"
                />
            </div>
        </Popover>

        <AlertsSnackBar
            handleClose={() => setAlertCopy(false)}
            open={alertCopy}
            message={"Une ligne a été copiée"}
            success={true}
        />

        <DialogHelp
            open={openDialogHelp.open}
            handleClose={() => setOpenDialogHelp({title: "", content: "", open: false})}
            title={openDialogHelp.title} content={openDialogHelp.content}
        />

        <DialogFilename
            formatDataWithoutSelect={formatDataWithoutSelect}
            type={openDialogFileName.type}
            open={openDialogFileName.open}
            handleClose={() => setOpenDialogFileName({open: false, type: "PDF"})}
            allData={data}
            data={formatData}
            origin={origin}
            exportUrl={exportUrl}
            filter={filter}
        />

        <Popover
            open={Boolean(anchorElExport)}
            anchorEl={anchorElExport}
            onClose={handleCloseExport}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List sx={{p: 0}}>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "EXCEL"})}}>
                        <ListItemIcon>
                            <DescriptionIcon/>
                        </ListItemIcon>

                        <ListItemText primary="Excel avec les lignes sélectionnées"/>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "EXCEL_ALL"})}}>
                        <ListItemIcon>
                            <DescriptionIcon/>
                        </ListItemIcon>

                        <ListItemText primary="Excel avec toutes les données du tableau"/>
                    </ListItemButton>
                </ListItem>

                <Divider/>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "PDF"})}}>
                        <ListItemIcon>
                            <PictureAsPdfIcon/>
                        </ListItemIcon>

                        <ListItemText primary="PDF avec les lignes sélectionnées"/>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "PDF_ALL"})}}>
                        <ListItemIcon>
                            <PictureAsPdfIcon/>
                        </ListItemIcon>

                        <ListItemText primary="PDF avec toutes les données du tableau"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>

        {/* @ts-ignore */}
        <Dialog component={'form'} onSubmit={handleSubmit} open={openDialogDuplication} onClose={handleCloseDuplication}>
            <DialogTitle>
                Nom de la duplication
            </DialogTitle>

            <DialogContent>
                <TextField
                    required
                    value={nameDuplication}
                    onChange={(event: any) => setNameDuplication(event.target.value)}
                    variant={"outlined"}
                    fullWidth
                    inputProps={{autoComplete: 'off'}}
                />
            </DialogContent>

            <DialogActions>
                <Button>
                    Annuler
                </Button>

                <LoadingButton loading={isLoadingDuplicate} type={"submit"} variant={'contained'}>
                    Duplicate
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}